<template>
  <div class="jobWrapper">
    <applied-and-saved-jobs-tab :tabs="tabs" @tab:click="tabSwitch" />
    <router-view></router-view>
  </div>

</template>

<script>

import {mapGetters} from 'vuex'
import AppliedAndSavedJobsTab from "../reuseables/AppliedAndSavedJobsTab";
// import ExploreJobByLocationCard from "@/components/dashboard/ExploreJobByLocationCard";
export default {
name: "Jobs",
  components: {AppliedAndSavedJobsTab},

  data: ()=>{
  return{
    sortBy: "Latest",
    dialog: false,
    filteredBaseOnCategory: [
        {id: 1, name:"Job"},
      {id: 2, name:"People"},
      {id: 3, name:" Company"}],
    filteredBasedOnLocation:[
        {id: 1, name:"on-site"},
        {id: 2, name: "Remote"}
        ],
    filteredBaseOnJobType: [ {id: 1, name: "Full-time"} ,{id: 2, name: "Part-time" },{id: 3, name: "Contract"} , {id: 4, name: "Internship" }, {id: 5 , name: "Flexible" } ],
    filteredBasedOnAlphabet:["Latest", "Alphabetically"],
    showMenu: false,
    selectedLocation: [],
    selectedCategory: [],
    selectedJobType: [],
    mobileSelectedCategory: [],

    tabs:[
      {
        to: "AppliedJobs",
        name: "Applied",
        exact : true
      },
      {
        to: "SavedJobs",
        name: "Saved",
        exact : true
      }
    ],
  }
  },
  computed: {
  ...mapGetters('jobSearch', ['searchResult']),
    // eslint-disable-next-line vue/return-in-computed-property
    filteredSearch: function() {
      const filteredProducts = this.searchResult
          .filter(() => true); // replace with your existing filter conditions here

      // apply sort function
      const sortFunction = this.getSorter();
      return sortFunction ? filteredProducts.sort(sortFunction) : filteredProducts;
    }

  },
  methods: {
  tabSwitch(tab){
    if (this.$route.name !== tab)
    this.$router.push({name:tab})
  },
    remove(item, arrayToRemoveToRemoveFFrom) {
      const index = arrayToRemoveToRemoveFFrom.indexOf(item.id);
      if (index >= 0) arrayToRemoveToRemoveFFrom.splice(index, 1);
    },
    getSorter() {
      switch (this.sortBy) {
        case 'Latest':
          return
        case 'Alphabetically':
          return (a,b) => b.title > a.title ? -1 : a.title === b.title ? 0 : 1;
        default:
          return null;
      }
    },
    selectedArray(name, selectedArray){
      if (selectedArray.includes(name)) {
       return selectedArray = selectedArray.filter(category => category.name !== name.name)
      } return selectedArray.push(name)
    }
  },
}
</script>

<style scoped lang="scss">
.title-bold{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #475661;
}
.desc{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.mobileCard{
  border-radius: 32px 32px 0px 0px;
}

.preference{
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    display: none;
  }
}
.mobileFilter{
  display: none;
  @media (max-width: 767px) {
    display: flex;
  }
}
.jobWrapper {
  .some-style > > > .v-input__slot::before {
    border-style: none !important;
  }

  ::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border: none !important;
  }

  ::v-deep .v-application .primary--text {
    color: red !important;
  }


}
::v-deep .v-dialog:not(.v-dialog--fullscreen).v-dialog {
margin: 0 !important;
  max-height: 100%;
  @media screen  and (min-width: 1024px){
    display: none !important;
  }
}
.theme--light.v-application {
  @media screen  and (min-width: 1024px){
    display: none;
  }
}
::v-deep .theme--light.v-application .theme--dark.v-overlay {
  display: none !important;
}

.v-dialog__content{
  top:50%!important;
}
.mobileSelect{

}

.filterTitle{
  background: #FFFFFF;
  border: 1px solid #D0DCE4;
  box-sizing: border-box;
  border-radius: 8px;
  line-height: 28px;
  color: #142E70;
}
.filterHeader{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;

}
.filterTitleOnClick{
  background: #F4F7FF;
  border: 1px solid #142E70;
  box-sizing: border-box;
  border-radius: 8px;

}


</style>